<template>
  <div>
    <CartList :cartList="cartList" />
    <footer class="bg-dark text-center text-white fixed-bottom">
      <div class="text-center p-3" style="background-color: #ebebeb">
        <a
          class="text-white"
          href="https://github.com/BYUCS260/grocery-store-dahenderson98"
          >GitHub Repository</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
import CartList from "../components/CartList.vue";
export default {
  name: "CartObj",
  components: {
    CartList,
  },
  data() {
    return {
      cartList: this.$root.$data.cart,
    };
  },
  methods: {},
};
</script>
