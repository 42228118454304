<template>
  <div>
    <div class="pure-menu pure-menu-horizontal">
      <ul class="pure-menu-list">
        <li class="pure-menu-item">
          <a @click="select('United States')" href="#" class="pure-menu-link"
            >United States</a
          >
        </li>
        <li class="pure-menu-item">
          <a @click="select('Canada')" href="#" class="pure-menu-link"
            >Canada</a
          >
        </li>
        <li class="pure-menu-item">
          <a @click="select('Mexico')" href="#" class="pure-menu-link"
            >Mexico</a
          >
        </li>
        <li class="pure-menu-item">
          <a @click="select('Brazil')" href="#" class="pure-menu-link"
            >Brazil</a
          >
        </li>
      </ul>
    </div>
    <ProductList :products="products" />
    <footer class="bg-dark text-center text-white fixed-bottom">
      <div class="text-center p-3" style="background-color: #ebebeb">
        <a
          class="text-white"
          href="https://github.com/BYUCS260/grocery-store-dahenderson98"
          >GitHub Repository</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
import ProductList from "../components/ProductList.vue";
export default {
  name: "BrowseObj",
  components: {
    ProductList,
  },
  data() {
    return {
      country: "",
    };
  },
  computed: {
    products() {
      return this.$root.$data.products.filter(
        (product) => product.country === this.country
      );
    },
  },
  methods: {
    select(country) {
      this.country = country;
    },
  },
};
</script>
